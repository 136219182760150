
import { ref, defineComponent } from 'vue'
import * as api from '../../../utils/api'
import Menu from '../../../components/menu/Hospital.vue'
import Card from '../../../components/Card.vue'
import { MobileCheck } from '@vicons/carbon'
import { useDialog } from 'naive-ui'

export default defineComponent({
	components: {
		Menu,
		Card,
		MobileCheck,
	},
	data() {
		const dialog = useDialog()
		const confirmDialog = (title: string, content: string) => {
			return new Promise<boolean>((resolve, reject) => {
				dialog.warning({
					title,
					content,
					positiveText: 'OK',
					negativeText: 'キャンセル',
					maskClosable: false,
					closable: false,
					onNegativeClick: () => {
						reject(true)
					},
					onPositiveClick: () => {
						resolve(true)
					},
				})
			})
		}
		return {
			hasCard: false,
			allTicket: 0,
			status: null,
			loading: false,
			totalLoading: true,
			nowTrial: false,
			remainTicket: 0,
			stripeCustomer: 'card',
			value: ref(1),
			confirmDialog,
		}
	},
	mounted: async function () {
		document.title = 'サブスクリプションの管理 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				this.totalLoading = true
				const data = await api.get('/v1/hospital/my')
				this.allTicket = data.allTicket
				this.remainTicket = data.remainTicket
				this.status = data.stripePaymentStatus
				this.hasCard = data.allTicket > 0
				this.totalLoading = false
				this.nowTrial = data.nowTrial
				this.stripeCustomer = data.stripeCustomer
			} catch (e: any) {
				alert(e.message || 'エラー')
			}
		},
		goToCheckout: async function () {
			if (this.hasCard) {
				if (!(await this.confirmDialog('追加購入しますか？', `${this.value}個のやり取り可能数を新たに追加します。全ての請求が毎月この日に発生します。`))) return false
				const amount = this.value + this.allTicket
				this.value = 1
				try {
					this.loading = true
					const data = await api.post('/v1/hospital/subscribe/update', { amount })
					this.loading = false
					this.value = 1
					this.init()
					location.reload()
				} catch (e: any) {
					this.loading = false
					alert(e.message || 'エラー')
				}
			} else {
				this.$router.push(`/hospital/subscribe/new?amount=${this.value}`)
			}
		},
		calc: function (amount: number) {
			const yen = amount * 9680
			if (yen < 0) return '-'
			return yen.toLocaleString()
		},
		receipt: function () {
			this.$router.push(`/hospital/subscribe/customer_portal`)
		},
		cancel: async function () {
			this.$router.push(`/hospital/subscribe/remove`)
		},
	},
})
